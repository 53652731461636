
















































































































































import { Vue, Component } from 'vue-property-decorator'
import {
  BButton,
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue'
import { IPromotion } from '@/interfaces/IPromotion'
import { promotionsStore } from '@/store'
import { showError, showMessage } from '@/helpers/notifications'
const { VueGoodTable } = require('vue-good-table')

@Component({
  components: {
    VueGoodTable,
    BButton,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    DeleteConfirmationModal,
  },
})
export default class Promotions extends Vue {
  $refs!: {
    deleteModal: HTMLElement & { show: (title: string) => Promise<boolean> }
  }

  updateFilterTimeout: ReturnType<typeof setTimeout> | null = null
  searchQuery = ''
  isLoading = false
  columns: Array<{
    field: string
    label: string
    type?: string
    sortable?: boolean
  }> = [
    {
      field: 'enabled',
      label: '',
    },
    {
      field: 'title',
      label: 'Название',
    },
    {
      field: 'discount',
      label: 'Скидка',
      type: 'number',
    },
    {
      field: 'customDiscount',
      label: 'Скидка на отдельные курсы',
      sortable: false,
    },
    {
      field: 'dateStart',
      label: 'Дата начала',
    },
    {
      field: 'dateEnd',
      label: 'Дата окончания',
    },
    {
      field: 'url',
      label: 'url',
    },
    {
      field: 'buttons',
      label: '',
      sortable: false,
    },
  ]
  paginationOptions = {
    enabled: true,
    mode: 'pages',
    perPageDropdown: [10, 50, 100],
    dropdownAllowAll: false,
    nextLabel: '',
    prevLabel: '',
    rowsPerPageLabel: 'на странице',
    ofLabel: 'из',
    pageLabel: 'страница',
  }
  tableOptions: {
    page: number
    perPage: number
    orderBy: Array<{ field: string; type: 'asc' | 'desc' }> | null
  } = {
    page: 1,
    perPage: 10,
    orderBy: null,
  }

  get promotions() {
    return promotionsStore.promotions
  }

  get totalRows() {
    return promotionsStore.totalPromotions
  }

  async mounted() {
    await this.loadPromotions()
  }

  onPageChange(params: { currentPage: number }) {
    this.tableOptions.page = params.currentPage
    this.loadPromotions()
  }

  onPerPageChange(params: { currentPerPage: number }) {
    this.tableOptions.page = 1
    this.tableOptions.perPage = params.currentPerPage
    this.loadPromotions()
  }

  onSortChange(params: Array<{ field: string; type: 'asc' | 'desc' }>) {
    this.tableOptions.orderBy = params
    this.loadPromotions()
  }

  handleSearchInput() {
    if (this.updateFilterTimeout) {
      clearTimeout(this.updateFilterTimeout)
    }

    this.updateFilterTimeout = setTimeout(this.updateSearch, 1000)
  }

  updateSearch() {
    this.isLoading = true
    this.tableOptions.page = 1
    this.loadPromotions()
  }

  async loadPromotions() {
    await promotionsStore.fetchPromotions({
      ...this.tableOptions,
      searchQuery: this.searchQuery,
    })
  }

  async deletePromotion(promotion: IPromotion) {
    if (await this.$refs.deleteModal.show(promotion.title.ru!)) {
      await promotionsStore.deletePromotion(promotion.id)
    }
  }

  async onPromotionEnabledChanged(
    id: number,
    value: boolean,
    row: { enabled: boolean },
  ) {
    const promotion = this.promotions.find(p => p.id === id)

    if (!value && promotion?.enabled) {
      this.isLoading = true
      await promotionsStore.disablePromotion(id)
      this.isLoading = false
    } else if (value && promotion) {
      if (new Date(promotion.dateEnd).getTime() - new Date().getTime() < 0) {
        row.enabled = false
        return showError(
          'Вы пытаетесь активировать акцию, которая уже закончилась',
        )
      }

      this.isLoading = true
      await promotionsStore.enablePromotion(id)
      this.isLoading = false
    }
  }

  copyUrl(url: string) {
    navigator.clipboard.writeText(`https://main.wayup.in/${url}`)
    showMessage('Ссылка скопирована')
  }

  formatDate(dateString: string) {
    const date = new Date(dateString)

    return date.toLocaleString('ru', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
  }
}
