<template>
  <div>
    <vue-good-table :columns="columns" :rows="courses" class="prices-table">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'title'">
          <b-media
            vertical-align="center"
            class="d-flex align-items-center ml-1"
          >
            <template #aside>
              <b-aspect
                class="p-50 rounded d-flex align-items-center"
                aspect="1:1"
                :style="{
                  background: props.row.previewColor,
                }"
              >
                <b-img
                  class="rounded"
                  width="25"
                  height="25"
                  center
                  :src="props.row.previewUrl"
                  alt=""
                />
              </b-aspect>
            </template>
            {{ props.row.title }}
          </b-media>
        </div>

        <div v-else-if="props.column.field == 'price'" class="h-100">
          <b-form-input
            v-model="props.row.price"
            type="number"
            class="number-input"
            :state="props.row.priceState"
            @blur="changePrice(props.row)"
            @keydown="if ($event.key === 'Enter') $event.target.blur()"
          />
        </div>

        <div v-else-if="props.column.field == 'discount'" class="h-100">
          <b-form-input
            v-model="props.row.discount"
            type="number"
            class="number-input"
            :state="props.row.discountState"
            @blur="changeDiscount(props.row)"
            @keydown="if ($event.key === 'Enter') $event.target.blur()"
          />
        </div>

        <div v-else-if="props.column.field == 'prepayment'" class="h-100">
          <b-form-input
            v-model="props.row.prepayment"
            type="number"
            class="number-input"
            :state="props.row.prepaymentState"
            @blur="changePrepayment(props.row)"
            @keydown="if ($event.key === 'Enter') $event.target.blur()"
          />
        </div>

        <div
          v-else
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { showMessage, showError } from '@/helpers/notifications'
import { BFormInput, BAspect, BImg, BMedia } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: { BFormInput, BAspect, BImg, BMedia, VueGoodTable },
  data: function () {
    return {
      courses: [],
      columns: [
        { field: 'title', label: 'Название' },
        {
          field: 'price',
          label: 'Цена $',
          type: 'number',
          sortable: false,
          tdClass: 'input-cell',
        },
        {
          field: 'discount',
          label: 'Скидка %',
          type: 'number',
          sortable: false,
          tdClass: 'input-cell',
        },
        {
          field: 'prepayment',
          label: 'Предоплата $',
          type: 'number',
          sortable: false,
          tdClass: 'input-cell',
        },
        { field: 'paymentMethods', label: 'Методы оплаты', sortable: false },
      ],
    }
  },
  async mounted() {
    try {
      const response = await this.$axios.get('v1/courses-prices')
      this.courses = response.data.courses
      for (const course of this.courses) {
        course.priceState = undefined
        course.discountState = undefined
        course.prepaymentState = undefined
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async changePrice(row) {
      const course = this.courses.find(course => course.id === row.id)
      if (row.price < 1) {
        showError('Цена не может быть меньше 1$')
        row.priceState = false
        return
      }
      if (course.price === row.price) return
      if (await this.showQuestion(`Изменить цену в курсе "${row.title}"?`)) {
        if (
          await this.saveCourseInfo(course.id, {
            price: row.price,
          })
        ) {
          course.price = row.price
        }
      } else {
        row.price = course.price
      }
    },
    async changeDiscount(row) {
      const course = this.courses.find(course => course.id === row.id)

      if (row.discount < 0) {
        showError('Скидка не может быть меньше 0%')
        row.discountState = false
        return
      }
      if (row.discount > 100) {
        showError('Скидка не может быть больше 100%')
        row.discountState = false
        return
      }
      if (course.discount === row.discount) return
      if (await this.showQuestion(`Изменить скидку в курсе "${row.title}"?`)) {
        if (
          await this.saveCourseInfo(course.id, {
            discount: row.discount,
          })
        ) {
          course.discount = row.discount
        }
      } else {
        row.discount = course.discount
      }
    },
    async changePrepayment(row) {
      const course = this.courses.find(course => course.id === row.id)

      if (row.prepayment < 0) {
        showError('Предоплата не может быть меньше 0$')
        row.prepaymentState = false
        return
      }
      if (row.prepayment > row.price) {
        showError('Предоплата не может быть больше стоимости курса')
        row.prepaymentState = false
        return
      }
      if (course.prepayment === row.prepayment) return
      if (
        await this.showQuestion(`Изменить предоплату в курсе "${row.title}"?`)
      ) {
        if (
          await this.saveCourseInfo(course.id, {
            prepayment: row.prepayment,
          })
        ) {
          course.prepayment = row.prepayment
        }
      } else {
        row.prepayment = course.prepayment
      }
    },
    async showQuestion(text) {
      const result = await this.$bvModal.msgBoxConfirm(text, {
        title: ' ',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Да',
        cancelTitle: 'Отмена',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      if (result) return true
      return false
    },
    async saveCourseInfo(courseId, data) {
      try {
        await this.$axios.patch(`v1/courses/${courseId}/update-payments`, data)
        showMessage('Данные обновленны', 'Успех')
        return true
      } catch (error) {
        showError('Произошла ошибка при сохранении данных')
        console.error(error)
        return false
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-good-table.scss';

.prices-table {
  table {
    height: 100%;
  }

  th {
    padding: 5px !important;
    font-size: 1rem;
    padding-right: 1.5rem !important;
    text-align: center;
    vertical-align: middle !important;
  }

  td {
    padding: 5px !important;
    font-size: 1rem;

    & > div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .error {
    background: $danger;
  }

  .input-cell {
    padding: 0 !important;
  }

  .number-input {
    height: 100%;
    border-radius: 0;

    &:not(:focus) {
      border-color: transparent;
    }

    &.is-invalid {
      border-color: $danger;
    }
  }
}
</style>