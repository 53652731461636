<template>
  <div>
    <b-card no-body>
      <h3 class="m-1">Цены</h3>
      <PricesTable />
    </b-card>
    <b-card no-body class="mb-0">
      <div class="d-flex justify-content-between align-items-center m-1">
        <h3>Акции</h3>
        <router-link to="/promotions/add">
          <b-button variant="primary">Создать</b-button>
        </router-link>
      </div>
      <Promotions />
    </b-card>
  </div>
</template>
<script>
import PricesTable from '@/components/payments/PricesTable.vue'
import Promotions from '@/components/promotions/Promotions.vue'
import { BCard, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    PricesTable,
    Promotions,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
</style>